import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    <span className="heading-meta">About</span>
                    <h2 className="colorlib-heading">Who Am I?</h2>
                    <p>I'm a software engineer with special interests in middleware and backend development for complex scalable applications. I am very passionate about programming. </p>
                    <p>With over a decade of software development expertise, I am specialized in developing .NET and NodeJs based microservices, microfrontends and business applications. I also experienced in DevOps and toolchain for CI/CD process. I can containerizing apps and orchestrate deployment using kubernetes and helm templates. I am experienced in database technologies like PostgreSql, MS-Sql, MongoDB 
                        <br/>Let's connect to explore opportunities, share insights, and contribute to the ever-evolving tech landscape together! Drop an email to   
                        <a href="emailto:pravin.deshmukh12@gmail.com" target="_blank" rel="noopener noreferrer"> pravin.deshmukh12@gmail.com</a> .</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">Here are some of my expertise</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Applications Development </h3>
                    <p>I have more than 11 years of experience designing, implementing, leading and launching software products.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Databases  </h3>
                    <p>I also have special interests in database technologies and persisted storage mechanism.
                    </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-settings" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>I like to code but more than that I am realizing configuring stuff and optimizing processes is more enjoyable for me.</p>
                </div>
                </div>
            </div>
            
            {/* <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-command2" />
                </span>
                <div className="desc">
                    <h3>Tool Chain</h3>
                    <p>GitLab, TeamCity, Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box"> 
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div> */}
           
            </div>
        </div>
        </section>
      </div>
    )
  }
}
