import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">highlights</span>
                <h2 className="colorlib-heading animate-box">Timeline</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-briefcase3" />
                      </div>
                      <div className="timeline-label">
                        <h2>Principal Developer <span>Nov 2017-Present</span></h2>
                        <p>Working as a full stack developer for finance applications. 
                          <br/>Develop system using C#, .NET Core and JavaScript based micro-services and micro-front ends.
                          </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-briefcase3" />
                      </div>
                      <div className="timeline-label">
                        <h2>Software Developer <span>Dec 2015-Nov 2017</span></h2>
                        <p>Developing web based apps with .Net MVC
                          Following Test driven development.
                          Working on database systems such as SQL, MongoDB.
                          Service based achietecture using WCF and Web APIs.
                          </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-briefcase3" />
                      </div>
                      <div className="timeline-label">
                        <h2>Software Developer <span>Jul 2014-Dec 2015</span></h2>
                        <p>Developing web based apps with .Net MVC, WCF and Web API
                          </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-briefcase3" />
                      </div>
                      <div className="timeline-label">
                        <h2>Software Developer <span>Jul 2012-Jul 2014</span></h2>
                        <p>Software Development of ERP based application in VB.NET and C#.NET
                          </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-study" />
                      </div>
                      <div className="timeline-label">
                        <h2>Graduation from Shivaji University <span>2008-2012</span></h2>
                        <p>I have done my under-graduation studies in Computer Science and Engineering. </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-study" />
                      </div>
                      <div className="timeline-label">
                        <h2>Primary and Higher Education <span>2003-2015</span></h2>
                        <p>I have completed my higher secondary education with major subjects as Computer Science, Physics,Chemistry & Maths.</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
